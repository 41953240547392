.nav-link {
    position: relative;
    color: inherit;
    text-decoration: none;
}

.nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -2px;
    /* Adjust to move the underline closer or further from the text */
    left: 50%;
    background-color: currentColor;
    transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.nav-link:hover::after {
    width: 100%;
    left: 0;
}