body {
  font-family: "Roboto" !important;
}


* {
  scroll-behavior: smooth !important;
}



.noti {
  animation: zooom 5s infinite;
  /* transition: all 0.5s ease-in-out; */
}

@keyframes zooom {
  0% {
    transform: scale(0.5)
  }

  50% {
    transform: scale(0.88)
  }

  100% {
    transform: scale(0.5)
  }

}

.react-tel-input .flag-dropdown {
  background: transparent;
  border-radius: 12px !important;
}

.react-tel-input .flag-dropdown .selected-flag {
  background: transparent;
  position: relative;
  border-radius: 12px !important;
  width: 55px !important;
  left: 0;
}

.react-tel-input .flag-dropdown .selected-flag .flag {
  left: 13px;
}

.react-tel-input .flag-dropdown .selected-flag:hover {
  background: transparent;
}

.language-item:hover {
  font-weight: 500;
}

.drop-down-wrapper {
  height: 0px !important;
  visibility: hidden;
}

.langIcon:hover .drop-down-wrapper {
  visibility: visible !important;
  height: 70px !important;
  padding: "0.5rem 0" !important;
}


.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

a {
  text-decoration: none !important;
  transition: all 0.1s ease;
}

nav {
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
}

#profile-drawer {
  background-color: #dbdbdb;
  position: relative;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

#profile-drawer.open {
  opacity: 1;
}


.PlaceInput:focus {
  border: none !important;
}


/* -------------------------------------------------------------------------------------------------------------- */

.propertyCard:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 9px 1px #1e243869;
  transition: all 0.2s ease-in-out;
}

/* ------------------------------------------------CHECK BOX RADIO--------------------------------------------------------------- */

.custom-radio input {
  display: none;
}

.custom-radio {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
}

/* Style of the custom checkbox */
.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-radio input:checked+.checkmark:after {
  content: "";
  position: absolute;
  display: block;
  left: 6px;
  top: 1px;
  width: 6px;
  height: 12px;
  border-radius: 0px 0px 2px 0px;
  border: solid #1f2438;
  border-width: 0px 2px 2px 0;
  box-shadow: 5px 0px 0px -1px #0003;
  transform: rotate(45deg);

}

/* ----------------------- Footer --------------------------- */
.footerList:hover {
  /* color: red !important; */
  font-weight: 700 !important;
}


/* ------------------------------------------------ CHECK BOX RADIO --------------------------------------------------------------- */

/* ::-webkit-scrollbar {
  width: 5px;
  height: 8px;
} */

/* ::-webkit-scrollbar-thumb {
  background-color: #1f2438;
} */

/* ::-webkit-scrollbar-track {
  background-color: #1f243850;
} */

::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}